import React, { useContext } from "react";
import styled from "styled-components";
import flexCss from "../../../utils/flexCss";
import dontForward from "../../../utils/dontForward";
import useBreakpointMatch from "../../../hooks/useBreakpointMatch";
import NoWrap from "../../../common/NoWrap";
import TextLink from "../../../common/TextLink";
import Remove from "../../../common/icons/Remove";
import PossibleImage from "../../../common/PossibleImage";
import FiltersContext from "../../../contexts/FiltersContext";
import TrackingContext from "../../../contexts/TrackingContext";
import ScrollSlider from "../../../common/ScrollSlider";

const FilterBarContainer = styled("div").withConfig(dontForward("horizontal"))<{ horizontal: boolean }>(({ horizontal, theme }) => (horizontal ? {
    ...flexCss("h", "space-between", "flex-start"),
    marginTop: theme.baseUnit * 2,
    marginBottom: theme.baseUnit,
    "& > *": {
        marginTop: 10
    },
    "& > :not(:last-child)": {
        marginRight: theme.baseUnit * 2
    }
} : {
    marginTop: theme.baseUnit * 2,
    marginBottom: theme.baseUnit * 2
}));

const FilterInfoText = styled("span").withConfig(dontForward("horizontal"))<{ horizontal: boolean }>(({ horizontal, theme }) => (horizontal ? {
    ...flexCss("h", "space-between"),
    marginTop: theme.baseUnit
} : {
    flex: "1 1 230px",
    textAlign: "right"
}));

const FilterBubbles = styled("div")(({ theme }) => ({
    flexGrow: 1,
    ...flexCss("h", "flex-start"),
    marginTop: 0,
    flexWrap: "wrap",
    "& > :not(:last-child)": {
        marginRight: theme.baseUnit * 2
    }
}));

const FilterBubble = styled("a").withConfig(dontForward("active"))<{ active: boolean }>(({ theme, active }) => ({
    height: theme.baseUnit * 5,
    borderRadius: (theme.baseUnit * 5) / 2,
    lineHeight: `${(theme.baseUnit * 5) - 4}px`, // - border width
    paddingLeft: theme.baseUnit * 2,
    paddingRight: active ? 38 : theme.baseUnit * 2,
    whiteSpace: "nowrap",
    border: `2.5px solid ${active ? theme.palette.tertiary.main : theme.palette.text}`,
    color: active ? theme.palette.tertiary.contrastText : theme.palette.text,
    background: active ? "rgb(0, 144, 54)" : theme.palette.white,
    cursor: "pointer",
    position: "relative",
    marginBottom: theme.baseUnit,
    opacity: 0.8,
    "&:hover": {
        opacity: 1
    }
}));

const FilterBubbleIcon = styled(PossibleImage).withConfig(dontForward("active"))<{ active: boolean }>(
    ({ active }) => ({
        height: "1.2em",
        marginTop: "-0.15em",
        marginBottom: "-0.15em",
        marginRight: "0.5em",
        verticalAlign: "middle",
        filter: active ? "invert()" : undefined
    })
);

const FilterBubbleRemoveIcon = styled(Remove)({
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 6,
    height: "calc(100% - 10px)",
    marginTop: "auto",
    marginBottom: "auto",
    color: "rgb(0, 123, 45)",
    "&:hover": {
        color: "rgb(0, 102, 38)"
    }
});

interface IFilterBarProps {
    availableFilters: Array<string>;
    allArticlesCount: number;
    filteredArticlesCount: number;
    categoryId?: string;
}

const FilterBar: React.FC<IFilterBarProps> = ({ availableFilters, allArticlesCount, filteredArticlesCount, categoryId }) => {

    const filtersContext = useContext(FiltersContext);
    const isAtLeastSmall = useBreakpointMatch("sm");

    const trackingContext = useContext(TrackingContext);

    const filters = filtersContext.all.filter(
        f => availableFilters.includes(f.id) || filtersContext.active.includes(f.id)
    );

    const onFiltersRemove = () => filtersContext.disableAll();

    const filterBubbles = (
        <FilterBubbles>            
            {filters.map(({ id, label, iconUrl }) => {

                const isFilterActive = filtersContext.isActive(id);
                const toggleFilter = () => {
                    if (!isFilterActive) {
                        trackingContext.trackEvent("filter", {
                            id,
                            categoryId
                        });
                    }
                    filtersContext.toggle(id);
                };

                return (
                    <FilterBubble
                        key={id}
                        active={isFilterActive}
                        onClick={toggleFilter}
                    >
                        {id && (
                            <FilterBubbleIcon src={iconUrl || undefined} active={isFilterActive} />
                        )}
                        {label}
                        {isFilterActive && (
                            <FilterBubbleRemoveIcon onClick={toggleFilter} />
                        )}
                    </FilterBubble>
                );
            })}            
        </FilterBubbles>
    );

    if (filters.length === 0) {
        return (
            <></>
        );
    }

    if (isAtLeastSmall) {

        return (
            <FilterBarContainer horizontal={true}>
                <span>Filtern</span>
                {filterBubbles}
                <FilterInfoText horizontal={false}>
                    {(filteredArticlesCount < allArticlesCount) ? (
                        <>
                            <NoWrap>{filteredArticlesCount} von {allArticlesCount}</NoWrap> Artikeln sichtbar{" | "}
                            <TextLink onClick={onFiltersRemove}><NoWrap>alle Filter aufheben</NoWrap></TextLink>
                        </>
                    ) : `alle ${allArticlesCount} Artikel sichtbar`}
                </FilterInfoText>
            </FilterBarContainer>
        );
    }

    return (
        <FilterBarContainer horizontal={false}>
            <ScrollSlider>
                {filterBubbles}
            </ScrollSlider>
            {(filteredArticlesCount < allArticlesCount) && (
                <FilterInfoText horizontal={true}>
                    <NoWrap>{filteredArticlesCount} von {allArticlesCount} Artikel</NoWrap>
                    <TextLink onClick={onFiltersRemove}><NoWrap>Filter aufheben</NoWrap></TextLink>
                </FilterInfoText>
            )}
        </FilterBarContainer>
    );
};

export default FilterBar;