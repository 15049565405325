import React, { useCallback, useEffect, useRef, useState } from "react";
import SwitchTransition from "react-transition-group/SwitchTransition";
import styled from "styled-components";
import { Special } from "../../generated/graphql";
import dontForward from "../../utils/dontForward";
import flexCss from "../../utils/flexCss";
import valuesCss from "../../utils/valuesCss";
import isBrowserEnv from "../../utils/isBrowserEnv";
import FadeLeftRight from "../transitions/FadeLeftRight";
import OfferedPrice from "./OfferedPrice";

const PriceDisplayContainer = styled("section").withConfig(dontForward("highlighted"))<{ highlighted: boolean }>(({ theme, highlighted }) => ({
    ...flexCss("v"),
    minWidth: highlighted ? 86 : 80,
    "--price-bg": highlighted ? theme.palette.secondary.main : theme.palette.quaternary.main,
    "--price-text": highlighted ? theme.palette.secondary.contrastText : theme.palette.quaternary.contrastText,
    [theme.media("sm")]: {        
        minWidth: 100
    }
}));

const NormalPrice = styled("footer")(({ theme }) => ({
    textAlign: "center",
    background: "var(--price-bg)",
    color: "var(--price-text)",
    marginTop: -3,
    fontSize: 12,
    lineHeight: "1.5",
    fontWeight: theme.typography.bold,
    padding: "0 0.4em",
    [theme.media("sm")]: {        
        fontSize: 14
    }
}));

const OfferedPriceBlock = styled(OfferedPrice)(({ theme }) => ({
    background: theme.palette.white,
    color: theme.palette.black,
    border: "3px solid var(--price-bg)",
    padding: valuesCss("px", 4, 8, 6, 8),
    textAlign: "center",
    [theme.media("sm")]: {        
        fontSize: 36
    }
}));

const priceFormat =  new Intl.NumberFormat(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

let globalIntervalId: number | undefined;
const GlobalIntervalEvent = isBrowserEnv() && new Event("globalInterval");

const ThreeDaysPriceText: React.FC = () => {

    const [flip, setFlip] = useState(false);
    const nodeRef = useRef(null);

    const toggleFlip = useCallback(() => setFlip(!flip), [flip]);

    useEffect(() => {
        if (!globalIntervalId) {
            globalIntervalId = window.setInterval(() => {
                if (GlobalIntervalEvent) {
                    document.dispatchEvent(GlobalIntervalEvent);
                }
            }, 6000);
        }

        document.addEventListener("globalInterval", toggleFlip);

        return () => document.removeEventListener("globalInterval", toggleFlip);

    }, [toggleFlip]);

    return (
        <SwitchTransition mode="out-in">
            <FadeLeftRight key={flip.toString()} nodeRef={nodeRef}>
                <span ref={nodeRef}>{flip ? "DO bis SA" : "3-Tage-Preis"}</span>
            </FadeLeftRight>
        </SwitchTransition>
    );
};

interface IPriceDisplayProps {
    offered: number;
    normal: number | null;
    hideNormalPrice: boolean;
    special?: Special | null;
    className?: string;
}

const PriceDisplay: React.FC<IPriceDisplayProps> = ({ offered, normal, hideNormalPrice, special, className }) => (
    <PriceDisplayContainer className={className} highlighted={special === Special.BigHit || special === Special.MondayBigHit || special === Special.ThreeDaysPrice || special === Special.SaturdayBigHit}>
        {special === Special.BigHit ?
            <NormalPrice><span>Knüller</span></NormalPrice> :
            null}
        <OfferedPriceBlock price={offered} skew={true} />
        <NormalPrice>
            {special === Special.MondayBigHit ? (
                <span>Montagsknüller</span>
            ) : special === Special.ThreeDaysPrice ? (
                <ThreeDaysPriceText />
            ) : special === Special.SaturdayBigHit ? (
                <span>Samstagskracher</span>
            ) : special === Special.OnlyNormal ? (
                <span></span>
            ) : (hideNormalPrice && normal || offered == normal) ? (
                <span>Aktionspreis</span>
            ) : !normal ? (
                <span></span>
            ) : (
                <><span>statt</span> {normal && priceFormat.format(normal)}</>
            )}
        </NormalPrice>
    </PriceDisplayContainer>
);

export default PriceDisplay;